import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactGA from 'react-ga';


class Home extends Component {
  render() {
    return (
      <div>
        <div className="splash">
          <img src="/img/cocktail-pro-light-scaled.png" className="logo" />
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <ReactGA.OutboundLink
              eventLabel="App_Store_Click"
              to="https://itunes.apple.com/us/app/cocktail-pro-drink-recipes/id1445509858?ls=1&mt=8"
              target="_blank">
              <img alt='Get it on the Apple App Store' src='/img/app-store-badge.png' style={{maxHeight: 68, width: 'auto'}} />
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="Play_Store_Click"
              to="https://play.google.com/store/apps/details?id=com.stithholdings.cocktailpro&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank">
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' style={{maxHeight: 100, width: 'auto'}} />
            </ReactGA.OutboundLink>
          </div>
        </div>

        <div className="intro">
          <h1 className="align-center">The Best Cocktail Recipes at Your Fingertips</h1>
          <p>Find the best drink and cocktail recipes in Cocktail Pro's vast database of delicious cocktail recipes. Add the ingredients you already have and find tasty cocktails to mix at your next party.</p>
        </div>

        <div className="screenshots">
          <img src="/img/cocktails.png" />
          <img src="/img/recipe.png" />
          <img src="/img/my-bar.png" />
          <img src="/img/login.png" />
        </div>

        <div className="content-wrapper feature">
          <header>
            <h1>Search by Your Ingredients</h1>
            <p>Add your ingredients to the 'My Bar' feature and find cocktail recipes you can mix with the ingredients you already have. </p>
          </header>
          <img src="/img/my-bar.png" />
        </div>

        <div className="content-wrapper feature">          
          <img src="/img/cocktails.png" style={{width: 'auto'}} />
          <header>
            <h1>Search and Sort To Find Great Drinks</h1>
            <p>Want to find great vodka cocktails with ingredients you already have? The top rated gin cocktails? Find the perfect drink recipe with our powerful search and sort feature. </p>
          </header>
        </div>

        <div className="content-wrapper feature">  
          <header>
            <h1>Save Your Favorite Cocktails</h1>
            <p>Save your favorite drink recipes for easy access and amaze your guests at your next party or gathering. </p>
          </header>        
          <img src="/img/favorites.png" />
          
        </div>

        <div className="content-wrapper feature">  
        <img src="/img/cocktails.jpg" style={{boxShadow: 'none'}} />
          <header>
            <h1>Advertise With Us</h1>
            <p>Interested in advertising on Cocktail Pro? Advertise your product to cocktail enthusiasts around the world. Please contact us for more information on advertising on Cocktail Pro.</p>
            <a  style={{fontSize: '1.1em'}} className="btn btn-success" href="/contact">Contact Us</a>
          </header>        
          
          
        </div>

       
      </div>
    );
  }
}

export default Home;
