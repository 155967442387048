import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import Ingredients from '../data/ingredients-names.json';

class Ingredient extends Component {

  constructor(props){
    super(props);

    this.state = {
      value: ''
    }
  }

  render() {
    return (
        <fieldset className="ingredient">
            <input required type="number" step="0.25" name="amount" className="form-control amount" placeholder="Amount" />
            <select required name="unit" className="form-control">
              <option value="oz">oz</option>  
              <option value="cl">cl</option>          
              <option value="cube">cube</option>
              <option value="dash">dash</option>
              <option value="drop">drop</option>
              <option value="drop">float</option>
              <option value="gram">gram</option>
              <option value="half">half</option>
              <option value="leaf">leaf</option>
              <option value="ml">ml</option>
              <option value="oz">oz</option>
              <option value="peel">peel</option>
              <option value="qaurter">quarter</option>
              <option value="scoop">scoop</option>
              <option value="shaving">shaving</option>
              <option value="splash">splash</option>
              <option value="stalk">stalk</option>
              <option value="tablespoon">tablespoon</option>
              <option value="teaspoon">teaspoon</option>
              <option value="third">third</option>
              <option value="twist">twist</option>
              <option value="wedge">wedge</option>
              <option value="whole">whole</option>
              <option value=" "></option>
            </select>

            <Autocomplete
                inputProps={{name: "ingredients", className: "form-control"}}
                getItemValue={(item) => item.label}
                items={Ingredients}
                shouldItemRender={(item, value) => item.toLowerCase().includes(value.toLowerCase())}
                sortItems = {(item1, item2, value)=>{
                  if(item1.length > item2.length){
                    return 1
                  } else {
                    return -1
                  }
                }}
                getItemValue={item => {
                  return item.split(' ')
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(' ');
                }}
                renderItem={(item, isHighlighted) =>
                  <div key={item} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                    {item}
                  </div>
                }
                value={this.state.value}
                onChange={e => this.setState({ value: e.target.value })}
                onSelect={value => this.setState({ value })}
                wrapperStyle={{width: 'auto', minWidth: 400, display: 'inline-block'}}
              />
            <div>
              <span>Garnish</span>
              <select name="garnish">
                <option value="false">False</option>
                <option value="true">true</option>
              </select>
              <span>Optional</span>
              <select name="optional">
                <option value="false">False</option>
                <option value="true">true</option>
              </select>
            </div>
        </fieldset>  
    )
  }
}

export default Ingredient;


