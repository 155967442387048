import React, { Component } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import './Submission.css';

import Ingredient from './Ingredient-Input';

class Submission extends Component {

  constructor(props){
    super(props);

    this.state = {
      value: '',
      ingredients: [<Ingredient />]
    }

    this.addInputs = this.addInputs.bind(this);

  }


  addInputs(e){
    let parent = e.target.parentElement;
    let inputs = parent.childNodes[0].cloneNode(true);
    
    for(var i=0; i < inputs.childNodes.length; i++){
      inputs.childNodes[i].value = ''
      if(inputs.childNodes[i].name==="garnish" || inputs.childNodes[i].name==="optional" ){
        inputs.childNodes[i].value="false";
      }
    }
    parent.insertBefore(inputs, parent.childNodes[parent.childNodes.length-2]);
  }

  addIngredients(e){
   let ingredients = this.state.ingredients;
   ingredients.push(
      <Ingredient />
   )
        
    this.setState({
      ingredients
    })
  }

  removeInput(e){
    let parent = e.target.parentNode;
      parent.removeChild(parent.getElementsByTagName("FIELDSET")[parent.getElementsByTagName("FIELDSET").length - 1]);
  }


  loadFile(event) {
    var output = document.getElementById('output');
    output.src = URL.createObjectURL(event.target.files[0]);
  };


  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#016fb9'}}>
        <div style={{textAlign: 'center'}}>
          <img src="/img/cocktail-pro-light-scaled.png" style={{height: 200, padding: 25}} />
          <h3 style={{color: '#fff', fontWeight: 300}}>Recipe Submission</h3>
        </div>
        <div className="row submission">       
          <form method="POST" action="http://cocktails.us-west-2.elasticbeanstalk.com/upload" encType="multipart/form-data">
            <div className="form-group">
              <input type="text" className="form-control" name="name" placeholder="Drink Name" />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" name="iba" placeholder="IBA" />
            </div>
            <div className="form-group">
              <textarea name="description" className="form-control"  placeholder="Description" />
            </div>
            <div className="form-group">
              
          
              <img id="output"/>
              <div>
                <label style={{display: 'block', fontWeight: 'bold'}}>Image</label>
                <input type="file" name="image" accept="image/*" onChange={(e)=>this.loadFile(e)}  />
              </div>
            </div>
            <div className="form-group">
              <fieldset class="preparation" name="preparation">
                <input name="preparation" className="form-control equipment"  placeholder="Glassware" />
              </fieldset>
            </div>
            <div className="form-inline form-group">            
              {this.state.ingredients.map(i => {
                return i
              })}    
              <span onClick={(e)=>this.addIngredients(e)}><FaPlus /> Add Ingredient</span>
              <span onClick={(e)=>this.removeInput(e)}><FaMinus /> Remove Ingredient</span>
    
            </div>
            
    
            <div className="form-group">
              <fieldset className="tags" name="tags">
                <input name="tags" className="form-control" placeholder="Tags" />
              </fieldset>
              <span onClick={(e)=>this.addInputs(e)}><FaPlus /> Add Tag</span>
              <span onClick={(e)=>this.removeInput(e)}><FaMinus /> Remove Tag</span>
            </div>
            <div className="form-group">
              <fieldset className="tags" name="tags">
                <input name="steps" className="form-control step" placeholder="Preparation Step" />
              </fieldset>
              <span onClick={(e)=>this.addInputs(e)}><FaPlus /> Add Preparation Step</span>
              <span onClick={(e)=>this.removeInput(e)}><FaMinus /> Remove Preparation Step</span>
            </div>
            <input style={{width: '100%'}} className="btn btn-primary" type="submit" value="Submit" />
          </form>
        </div>
      </div>
    );
  }
}

export default Submission;
