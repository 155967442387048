import React, { Component } from 'react';
import '../App.css';

class Contact extends Component {
  render() {
    return (
      <div className="content-wrapper privacy align-center" style={{marginTop: '4em', minHeight: 600, justifyContent: 'center'}}>
          <img src="/img/cocktail-pro-light-scaled.png" style={{height: 250}} />
          <h1>Contact</h1>
          <p style={{fontSize: 18}}>If you are interested in advertising on Cocktail Pro, have questions, comments, or even just have a recipe you'd like to submit. Please email us at <a href="mailto: contact@cocktailpro.app">Contact@cocktailpro.app</a></p>
      </div>
    );
  }
}

export default Contact;
