import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Home from './components/Home';
import Privacy from './components/PrivacyPolicy';
import Contact from './components/Contact';
import Submission from './components/Submission';

import './bootstrap.css';
import './App.css';   

import ReactGA from 'react-ga';
ReactGA.initialize('UA-29486176-6');


class App extends Component {
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let year = new Date;
    year = year.getFullYear();
    return (
      <Router>
        <div>
          <div className="menu">
            <img src="/img/cocktail-pro-light-scaled.png" style={{height: 75}} />
            <div class="menu-items">                     
                <Link to="/">Home</Link>   
                <Link to="/contact">Contact</Link>   
                <Link to="/privacy-policy">Privacy Policy</Link>            
            </div>
          </div>
          <Route path="/" exact component={Home} />
          <Route path="/contact"  component={Contact} />
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/create" component={Submission} />
          <div class="footer content-wrapper download">
              <img src="/img/cocktail-pro-light-scaled.png" style={{height: 100}} />
              <p>&copy; {year} Stith Holdings LLC</p>   
                
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <ReactGA.OutboundLink
                eventLabel="App_Store_Click"
                to="https://itunes.apple.com/us/app/cocktail-pro-drink-recipes/id1445509858?ls=1&mt=8"
                target="_blank">
                <img alt='Get it on the Apple App Store' src='/img/app-store-badge.png' style={{maxHeight: 68, width: 'auto'}} />
              </ReactGA.OutboundLink>
              <ReactGA.OutboundLink
                eventLabel="Play_Store_Click"
                to="https://play.google.com/store/apps/details?id=com.stithholdings.cocktailpro&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank">
                  <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' style={{maxHeight: 100, width: 'auto'}} />
              </ReactGA.OutboundLink>    
           </div>
          </div>
        </div>
      </Router>
    );
  }
}



export default App;
